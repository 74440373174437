let url = ''
if (location.host == '47.106.201.148') { //正式
	url = 'http://47.106.201.148:2354/'; //正式
} else { //測試 localhost 
	// url = 'http://192.168.31.231:3785/'; //本地
	url = 'http://192.168.31.231:2354/'; //本地
	// url = 'http://47.106.201.148:3785/'; //測試
	// url = 'http://47.106.201.148:2354/'; //測試 
}
if(location.host == "qmisit.com"||location.host == "www.qmisit.com"|| location.protocol == 'https:'){
	// url = 'https://hk.qmisit.com:2346/'; 
	url = 'https://hk.qmisit.com:2354/'; 
}
module.exports = {
	url:url,
	gethtml : url +'web/gethtml',
	checkDomain :url +'talk/checkDomain',
	//首頁接口POST
	getFirstPage :url +'qmisitweb/getFirstPage',
	getAboutUsPage :url +'qmisitweb/getAboutUsPage',
	getPositionPage :url +'/qmisitweb/getPositionPage',
	getPrivacyPolicyPage :url +'/qmisitweb/getPrivacyPolicyPage',
	
	sendLeaveMsgEmail: 'https://hk.qmisit.com:3785/kaihu/sendLeaveMsgEmail',
}