import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apiUrl from '@/assets/js/api.js';
import inaxios  from '@/assets/js/axiosConfig.js'
import axios from 'axios'
import Antd from 'ant-design-vue';
// import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import  '@/assets/css/style(1).css';
import '@/assets/newantdesign.less';
// import '@/assets/axiosconfig.js'

inaxios()
Vue.prototype.$ajax = axios
Vue.prototype.$api = apiUrl

// Vue.use(AOS)
Vue.use(Antd)

Vue.config.productionTip = false
axios.defaults.withCredentials = true

new Vue({
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')
