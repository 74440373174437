import api from "./api.js"
import $store from '@/store/index.js'
import axios from 'axios'

export default function (){
	axios.interceptors.request.use(
	config=>{
		config.headers.token = $store.state.token
		return config
	},err=>{
		console.log(err)
	})
	axios.interceptors.response.use(
	response => {
		// console.log(response.data.code)
		// //拦截响应 统一处理
		if (response.data.code) {//失效
			if (response.data.code === 3000) {
				// App.oTool.warningT(response.data.qmismsg);
				location.href = '/'
			}
		}
		return response
	}, error => {
		//接口错误状态处理，无响应处理
		
		// App.oTool.warningT(error.data.qmismsg);
		return Promise.reject(error) //接口返回的错误信息
	})
}