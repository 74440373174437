import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: () => import('@/views/homepage/homepage.vue')
  },
  {
    path: '/sreachpage',
    name: 'sreachpage',
    component: () => import('@/views/sreachpage/sreachpage.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs/aboutUs.vue')
  },
  {
    path: '/post',
    name: 'post',
    component: () => import('@/views/post/post.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/contactUs/contactUs.vue')
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    meta:{title:'私隐政策'},
    component: () => import('@/agreement/privacypolicy.vue')
  },
  {
    path: '/personalStatement',
    name: 'personalStatement',
    component: () => import('@/agreement/personalStatement.vue')
  },
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  if(to.meta.title){
    document.title = to.meta.title + ' | 铧亚软件科技'
  }else{
    document.title = '铧亚软件科技'
  }
  next()
})
export default router
