<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  document.onkeydown = () => {
    //禁用F12
    if (window.event && window.event.keyCode == 123) {
      return false;
    //禁用ctrl+ c,
    } else if (window.event.ctrlKey  && window.event.keyCode == 67) {
      return false;
    } else if(window.event.keyCode == 67 && window.event.metaKey){
      return false;
    }else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
      return false;
    }else if (window.event.metaKey && window.event.shiftKey && window.event.keyCode == 73) {
      return false;
    }else if (window.event.shiftKey && window.event.keyCode == 121) {
      return false;
    }
  };
export default {
  name: 'App',
  data(){
   
  },
  mounted() {
    document.oncontextmenu = new Function("event.returnValue=false");
  }
}
</script>
<style>

</style>
